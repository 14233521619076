import { useEffect, useState } from "react";
import serverHelper from "../helpers/serverHelper";
import Pilot from "../interfaces/Pilot";
import { SessionManager } from "./useSessionManager";
import InputField from "../interfaces/InputField";
import Currency from "../interfaces/Currency";


export interface PilotManager {
  pilotFields: InputField[];
  pilots: Pilot[] | null;
  selectedPilot: Pilot | null;
  pilotCreated: boolean;
  pilotCurrency: Currency | null;
  retrievePilotFields: (userId?: string) => Promise<boolean>;
  retrievePilots: () => Promise<boolean>;
  addPilot: (pilot: Pilot) => Promise<boolean>;
  retrievePilot: (userId?: string) => Promise<boolean>;
  updatePilot: (pilot: Pilot) => Promise<boolean>;
  deletePilot: (userId?: string) => Promise<boolean>;
  clearSelectedPilot: () => void;
  retrievePilotCurrency: (userId?: string) => Promise<boolean>;
}

function usePilotManager(session: SessionManager): PilotManager {

  // State variables
  const [pilotFields, setPilotFields] = useState<InputField[]>([]);
  const [pilots, setpilots] = useState<Pilot[] | null>(null);
  const [selectedPilot, setSelectedpilot] = useState<Pilot | null>(null);
  const [pilotCreated, setPilotCreated] = useState<boolean>(true);
  const [pilotCurrency, setPilotCurrency] = useState<Currency | null>(null);

  // Clear state variables when the session is logged out
  useEffect(() => {
    if (session.authenticated === false) {
      setPilotFields([]);
      setpilots(null);
      setSelectedpilot(null);
      setPilotCreated(true);
      setPilotCurrency(null);
    }
  }, [session.authenticated]);

  /**
   * Retrieves pilot metadata from the server.
   * If no userId is provided, it uses the userId from the session.
   * @param userId - The userId to retrieve pilot metadata for.
   * @sets pilotFields - The pilot fields state.
   * @returns A boolean indicating whether the retrieval was successful.
   */
  const retrievePilotFields = async (userId?: string) => {
    if (!userId) {
      userId = session.user?.userId;
      if (!userId) {
        return false;
      }
    }
    try {
      const serverResponse = await serverHelper.get('/pilotMetadata?userId=' + userId);
      if (serverResponse.ok) {
        const pilotMetadata = await serverResponse.json();
        setPilotFields(pilotMetadata);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  /**
   * Retrieves all pilots from the server.
   * @sets pilots - The pilots state.
   * @returns A boolean indicating whether the retrieval was successful.
   */
  const retrievePilots = async () => {
    try {
      const serverResponse = await serverHelper.get('/pilots');
      if (serverResponse.ok) {
        const pilots = await serverResponse.json();
        setpilots(pilots);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  /**
   * Adds a pilot to the server.
   * Will use the userId from the session if it is not provided in the pilot object.
   * @param pilot - The pilot object to be added.
   * @sets selectedPilot - Sets the selected pilot state to that of the newly added pilot.
   * @returns A boolean indicating whether the pilot was successfully added.
   */
  const addPilot = async (pilot: Pilot) => {
    if (!pilot.userId) {
      pilot.userId = session.user?.userId as string;
      if (!pilot.userId) {
        return false;
      }
    }
    try {
      const serverResponse = await serverHelper.post('/pilot', pilot);
      if (serverResponse.ok) {
        const newpilot = await serverResponse.json();
        setSelectedpilot(newpilot as Pilot);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  /**
   * Retrieves a pilot from the server based on the provided userId.
   * If no userId is provided, it uses the userId from the session.
   * @param userId - The userId of the pilot to retrieve.
   * @sets selectedPilot - The selected pilot state.
   * @returns A boolean indicating whether the retrieval was successful.
   */
  const retrievePilot = async (userId?: string) => {
    if (!userId) {
      userId = session.user?.userId;
      if (!userId) {
        return false;
      }
    }
    try {
      const serverResponse = await serverHelper.get('/pilot?userId=' + userId);
      if (serverResponse.ok) {
        const pilot = await serverResponse.json();
        setSelectedpilot(pilot as Pilot);
        setPilotCreated(true);
        return true;
      } else {
        if (serverResponse.status === 404) {
          setPilotCreated(false);
        }
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  /**
   * Updates a pilot.
   * If no userId is provided, it uses the userId from the session.
   * @param pilot - The pilot object to be updated (must have a userId property).
   * @updates pilots - Triggers a server fetch to update the pilots state.
   * @returns A boolean indicating whether the update was successful or not.
   */
  const updatePilot = async (pilot: Pilot) => {
    if (!pilot.userId) {
      pilot.userId = session.user?.userId as string;
      if (!pilot.userId) {
        return false;
      }
    }
    try {
      const serverResponse = await serverHelper.patch('/pilot', pilot);
      if (serverResponse.ok) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  /**
   * Deletes a pilot.
   * If no userId is provided, it uses the userId from the session.
   * @param userId - The userId of the pilot to be deleted.
   * @updates pilots - Triggers a server fetch to update the pilots state.
   * @returns A boolean indicating whether the deletion was successful or not.
   */
  const deletePilot = async (userId?: string) => {
    if (!userId) {
      userId = session.user?.userId as string;
      if (!userId) {
        return false;
      }
    }
    try {
      const serverResponse = await serverHelper.del('/pilot?userId=' + userId);
      if (serverResponse.ok) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  /**
   * Clears the selected pilot state.
   * @updates selectedPilot - Sets the selected pilot state to null.
   */
  const clearSelectedPilot = () => {
    setSelectedpilot(null);
  }

  /**
   * Retrieves the pilot currency from the server.
   * If no userId is provided, it uses the userId from the session.
   * @param userId - The userId of the pilot to retrieve currency for.
   * @sets pilotCurrency - The pilot currency state.
   * @returns A boolean indicating whether the retrieval was successful.
   */
  const retrievePilotCurrency = async (userId?: string) => {
    if (!userId) {
      userId = session.user?.userId;
      if (!userId) {
        return false;
      }
    }
    try {
      const serverResponse = await serverHelper.get('/pilot/currency?userId=' + userId);
      if (serverResponse.ok) {
        const pilotCurrency = await serverResponse.json();
        setPilotCurrency(pilotCurrency);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  // Return the pilot manager object
  return {
    pilotFields,
    pilots,
    selectedPilot,
    pilotCreated,
    pilotCurrency,
    retrievePilotFields,
    retrievePilots,
    addPilot,
    retrievePilot,
    updatePilot,
    deletePilot,
    clearSelectedPilot,
    retrievePilotCurrency
  };
}

export default usePilotManager;