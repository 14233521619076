import React, { useEffect } from 'react';
import { IonIcon, IonLabel, IonPage, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs, useIonRouter, useIonViewWillEnter } from '@ionic/react';
import './Home.css';
import { SessionManager } from '../hooks/useSessionManager';
import { FlightManager } from '../hooks/useFlightManager';
import { airplane, list, medal, person, statsChart } from 'ionicons/icons';
import { Redirect, Route } from 'react-router';
import FlightList from '../tabs/FlightList';
import ViewFlight from './ViewFlight';
import FlightStats from '../tabs/FlightStats';
import MyAccount from '../tabs/PilotDetails';
import { PilotManager } from '../hooks/usePilotManager';
import CurrencyDetails from '../tabs/CurrencyDetails';

interface HomeProps {
    session: SessionManager;
    flightManager: FlightManager;
    pilotManager: PilotManager;
}

const Home: React.FC<HomeProps> = ({ session, flightManager, pilotManager }) => {

    // Initialise hooks
    const router = useIonRouter();

    // Force the user to enter their pilot details before using the app
    useEffect(() => {
        // Retrieve pilot details when userId becomes available
        if (session.user?.userId) {
            pilotManager.retrievePilot(session.user?.userId);
        }
        // If the pilot has not been created, redirect to pilot details page
        if (!pilotManager.pilotCreated) {
            router.push('/home/pilot-details');
        }
    }, [session.user?.userId, pilotManager.pilotCreated]);

    // Handle Home tab navigation
    return (
        <IonTabs>
            <IonRouterOutlet>
                <Redirect exact path="/home" to="/home/flights" />
                <Route path="/home/flights" render={() => <FlightList session={session} flightManager={flightManager} />} exact={true} />
                <Route path={["/home/flights/:id/:action", "/home/flights/:id", "/home/flights/new"]}>
                    <ViewFlight session={session} flightManager={flightManager} />
                </Route>
                <Route path="/home/stats" render={() => <FlightStats session={session} flightManager={flightManager} />} exact={true} />

                <Route path="/home/currency" render={() => <CurrencyDetails session={session} pilotManager={pilotManager} />} exact={true} />

                <Route path="/home/pilot-details" render={() => <MyAccount session={session} pilotManager={pilotManager} />} exact={true} />
            </IonRouterOutlet>

            <IonTabBar slot="bottom" translucent={true}>
                <IonTabButton tab="flights" href="/home/flights">
                    <IonIcon icon={airplane} style={{ "transform": "rotate(-90deg)" }} />
                    <IonLabel>Flight List</IonLabel>
                </IonTabButton>
                <IonTabButton tab="stats" href="/home/stats">
                    <IonIcon icon={statsChart} />
                    <IonLabel>Statistics</IonLabel>
                </IonTabButton>
                <IonTabButton tab="currency" href="/home/currency">
                    <IonIcon icon={medal} />
                    <IonLabel>Currency</IonLabel>
                </IonTabButton>
                <IonTabButton tab="pilot-details" href="/home/pilot-details">
                    <IonIcon icon={person} />
                    <IonLabel>Pilot Details</IonLabel>
                </IonTabButton>
            </IonTabBar>
        </IonTabs>
    );
};

export default Home;
