let baseUrl = 'http://localhost:8080'
if (process.env.NODE_ENV === 'production') {
    baseUrl = 'https://gliding-app-api.reidonly.co.uk'
}

const generalAbortController = () => {
    const controller = new AbortController();
    setTimeout(() => controller.abort(), 5000);
    const signal = controller.signal;
    return signal
}

const get = async (path: string) => {
    let attempts = 0;
    while (attempts < 3) {
        const signal = generalAbortController();
        try {
            const response = await fetch(`${baseUrl}${path}`, {
                method: 'GET',
                credentials: 'include',
                signal: signal,
            });
            return response;
        } catch (error) {
            attempts++;
        }
    }
    throw new Error('Failed to fetch after 3 attempts');
};

const post = async (path: string, body: any) => {
    let attempts = 0;
    while (attempts < 3) {
        const signal = generalAbortController();
        try {
            const response = await fetch(`${baseUrl}${path}`, {
                method: 'POST',
                credentials: 'include',
                body: JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json',
                },
                signal: signal,
            });
            return response;
        } catch (error) {
            attempts++;
        }
    }
    throw new Error('Failed to fetch after 3 attempts');
};

const patch = async (path: string, body: any) => {
    let attempts = 0;
    while (attempts < 3) {
        const signal = generalAbortController();
        try {
            const response = await fetch(`${baseUrl}${path}`, {
                method: 'PATCH',
                credentials: 'include',
                body: JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json',
                },
                signal: signal,
            });
            return response;
        } catch (error) {
            attempts++;
        }
    }
    throw new Error('Failed to fetch after 3 attempts');
};

const del = async (path: string) => {
    let attempts = 0;
    while (attempts < 3) {
        const signal = generalAbortController();
        try {
            const response = await fetch(`${baseUrl}${path}`, {
                method: 'DELETE',
                credentials: 'include',
                signal: signal,
            });
            return response;
        } catch (error) {
            attempts++;
        }
    }
    throw new Error('Failed to fetch after 3 attempts');
};

const register = async (email: string, password: string) => {
    let attempts = 0;
    while (attempts < 3) {
        const signal = generalAbortController();
        try {
            const response = await fetch(`${baseUrl}/register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
                signal: signal,
            });
            return response;
        } catch (error) {
            attempts++;
        }
    }
    throw new Error('Failed to fetch after 3 attempts');
}

const login = async (email: string, password: string) => {
    let attempts = 0;
    while (attempts < 3) {
        const signal = generalAbortController();
        try {
            const response = await fetch(`${baseUrl}/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({ email, password }),
                signal: signal,
            });
            return response;
        } catch (error) {
            attempts++;
        }
    }
    throw new Error('Failed to fetch after 3 attempts');
};

const logout = async () => {
    let attempts = 0;
    while (attempts < 3) {
        const signal = generalAbortController();
        try {
            const response = await fetch(`${baseUrl}/logout`, {
                method: 'POST',
                credentials: 'include',
                signal: signal,
            });
            return response;
        } catch (error) {
            attempts++;
        }
    }
    throw new Error('Failed to fetch after 3 attempts');
};

const getSession = async () => {
    let attempts = 0;
    while (attempts < 3) {
        const signal = generalAbortController();
        try {
            const response = await fetch(`${baseUrl}/session`, {
                method: 'GET',
                credentials: 'include',
                cache: 'no-cache',
                signal: signal,
            });
            return response;
        } catch (error) {
            attempts++;
        }
    }
    throw new Error('Failed to fetch after 3 attempts');
};

export default {
    get,
    post,
    patch,
    del,
    register,
    login,
    logout,
    getSession
}