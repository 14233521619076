import React from 'react';
import './InputList.css';
import { IonList, IonItem, IonInput, IonCheckbox, IonSelect, IonLabel, IonSelectOption, IonTextarea, IonButton, IonPicker, IonIcon } from '@ionic/react';
import { menu } from 'ionicons/icons';
import { Duration } from 'luxon';
import { InputListManager } from '../hooks/useInputListManager';

interface InputListProps {
    inputListManager: InputListManager;
}

const InputList: React.FC<InputListProps> = ({ inputListManager }) => {
    return (
        <IonList lines='full' className='ion-no-padding'>
            {inputListManager.inputFields.map(field => {
                const fieldValue = inputListManager.inputData[field.id];
                return (
                    <IonItem key={field.id} style={inputListManager.showErrors && !inputListManager.requiredFieldFilled(field) ? { '--background': 'rgba(var(--ion-color-danger-rgb), 0.25)' } : {}}>

                        {field.type === 'text' &&
                            <IonInput label={field.label + (field.required ? ' *' : '')} labelPlacement='floating' onIonInput={e => inputListManager.updateInputData(field.id, e.detail.value)} value={fieldValue} placeholder={field.placeholder} />
                        }
                        {field.type === 'number' &&
                            <IonInput type='number' label={field.label + (field.required ? ' *' : '')} labelPlacement='floating' onIonInput={e => inputListManager.updateInputData(field.id, e.detail.value)} value={fieldValue} placeholder={field.placeholder} />
                        }
                        {field.type === 'boolean' &&
                            <>
                                <IonCheckbox onIonChange={e => inputListManager.updateInputData(field.id, e.detail.checked)} checked={fieldValue}>{field.label + (field.required ? ' *' : '')}</IonCheckbox>
                            </>
                        }
                        {field.type === 'date' &&
                            <IonInput type='date' label={field.label + (field.required ? ' *' : '')} labelPlacement='floating' onIonInput={e => inputListManager.updateInputData(field.id, e.detail.value)} value={fieldValue} placeholder={field.placeholder} />
                        }
                        {field.type === 'select' &&
                            <>
                                <IonSelect interface='popover' onIonChange={e => inputListManager.updateInputData(field.id, e.detail.value)} value={fieldValue} placeholder={field.placeholder}>
                                    <IonLabel slot='label'>{field.label + (field.required ? ' *' : '')}</IonLabel>
                                    {field.options?.map(option => (
                                        <IonSelectOption key={option} value={option}>{option}</IonSelectOption>
                                    ))}
                                </IonSelect>
                            </>
                        }
                        {field.type === 'extendedText' &&
                            <>
                                <IonTextarea label={field.label + (field.required ? ' *' : '')} labelPlacement='floating' autoGrow={true} onIonInput={e => inputListManager.updateInputData(field.id, e.detail.value)} value={fieldValue} placeholder={field.placeholder} />
                            </>
                        }
                        {field.type === 'duration' &&
                            <>
                                <IonLabel>{field.label + (field.required ? ' *' : '')}</IonLabel>
                                <IonButton slot='end' id={field.id + '-picker'}>{fieldValue ? Duration.fromMillis(parseInt(fieldValue)).toFormat('h \'hrs\' mm \'mins\'') : 'Select'}</IonButton>
                                <IonPicker trigger={field.id + '-picker'} columns={[
                                    {
                                        name: 'hours',
                                        selectedIndex: fieldValue ? Math.floor(parseInt(fieldValue) / (60 * 60 * 1000)) : 0,
                                        suffix: 'hrs',
                                        options: Array.from({ length: 24 }, (_, i) => ({ text: i.toString().padStart(2, '0'), value: i.toString().padStart(2, '0') }))
                                    },
                                    {
                                        name: 'minutes',
                                        selectedIndex: fieldValue ? parseInt(fieldValue) / 60 / 1000 % 60 : 0,
                                        suffix: 'mins',
                                        options: Array.from({ length: 60 }, (_, i) => ({ text: i.toString().padStart(2, '0'), value: i.toString().padStart(2, '0') }))
                                    },
                                ]} buttons={[
                                    {
                                        text: 'Cancel',
                                        role: 'cancel'
                                    },
                                    {
                                        text: 'Confirm',
                                        handler: (value) => {
                                            const hoursInMilliseconds = parseInt(value.hours.value) * 60 * 60 * 1000;
                                            const minutesInMilliseconds = parseInt(value.minutes.value) * 60 * 1000;
                                            const timeInMilliseconds = hoursInMilliseconds + minutesInMilliseconds;
                                            inputListManager.updateInputData(field.id, timeInMilliseconds.toString());
                                        }
                                    }
                                ]}>
                                </IonPicker>
                            </>
                        }

                        {field.type !== 'select' && field.options && field.options.length > 0 &&
                            <>
                                <IonButton id={field.id + '-suggestion-button'} slot='end' fill='clear' color={'dark'}>
                                    <IonIcon id={field.id + '-suggestion-button'} slot='icon-only' icon={menu} />
                                </IonButton>
                                <IonPicker trigger={field.id + '-suggestion-button'} columns={
                                    [
                                        {
                                            name: 'suggestions',
                                            selectedIndex: field.options.findIndex(option => option === fieldValue),
                                            options: field.options.map(option => ({ text: option, value: option }))
                                        }
                                    ]} buttons={[
                                        {
                                            text: 'Cancel',
                                            role: 'cancel'
                                        },
                                        {
                                            text: 'Confirm',
                                            handler: (value) => {
                                                inputListManager.updateInputData(field.id, value.suggestions.value);
                                            }
                                        }
                                    ]
                                    }>
                                </IonPicker>
                            </>
                        }

                    </IonItem>
                )
            })}
        </IonList>
    );
}

export default InputList;