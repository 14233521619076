import React, { useEffect } from 'react';
import { SessionManager } from '../hooks/useSessionManager';
import { IonButton, IonButtons, IonCard, IonCol, IonContent, IonFab, IonFabButton, IonGrid, IonHeader, IonIcon, IonItem, IonItemDivider, IonItemGroup, IonLabel, IonList, IonPage, IonProgressBar, IonRefresher, IonRefresherContent, IonRow, IonTitle, IonToolbar, useIonViewWillEnter } from '@ionic/react';
import { checkbox, logOutOutline, pencil, squareOutline } from 'ionicons/icons';
import '../styles/SharedStyles.css';
import './FlightStats.css';
import CreateOrEditPilot from '../components/CreateOrEditPilot';
import { PilotManager } from '../hooks/usePilotManager';

interface PilotDetailsProps {
    session: SessionManager;
    pilotManager: PilotManager;
}

const PilotDetails: React.FC<PilotDetailsProps> = ({ session, pilotManager }) => {

    // Initialise states
    const [showCreateOrEditModal, setShowCreateOrEditModal] = React.useState(false);

    // Retrieve current pilot details when returning to the page (If the details have previously been retrieved)
    useIonViewWillEnter(() => {
        if (pilotManager.selectedPilot) {
            pilotManager.retrievePilot();
        }
    }, [pilotManager.selectedPilot]);

    // Retrieve current pilot details when the session userId becomes available (If the details are not already retrieved)
    useEffect(() => {
        if (pilotManager.selectedPilot === null) {
            pilotManager.retrievePilot();
        }
    }, [session.user?.userId]);

    // Retrieve current pilot details when the list is refreshed
    const refreshPilotDetails = async (e: CustomEvent) => {
        await pilotManager.retrievePilot();
        setTimeout(() => {
            e.detail.complete();
        }, 500);
    };

    // Show create or edit modal when the users pilot details are yet to be created
    useEffect(() => {
        if (!pilotManager.pilotCreated) {
            setShowCreateOrEditModal(true);
        }
    }, [pilotManager.pilotCreated]);

    // Get selected flight from flight manager
    const selectedPilot = pilotManager.selectedPilot;

    return (
        <IonPage id="view-flight-page">

            <IonHeader translucent>
                <IonToolbar>
                    <IonTitle>Pilot</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={() => session.logout()} title='Logout'>
                            <IonIcon slot="icon-only" src={logOutOutline} />
                        </IonButton>
                    </IonButtons>
                </IonToolbar> 
                {selectedPilot === null && pilotManager.pilotCreated && <IonProgressBar type="indeterminate"></IonProgressBar>}
            </IonHeader>

            <IonContent className='frostedGlassContent'>
                <IonRefresher slot="fixed" onIonRefresh={refreshPilotDetails}>
                    <IonRefresherContent></IonRefresherContent>
                </IonRefresher>
                {selectedPilot &&
                    <>
                        <IonGrid className='rootContentGrid'>
                            <IonRow>
                                <IonCol size='12' sizeLg='6'>
                                    <IonCard>
                                        <IonList lines='full' inset className='cardList'>
                                            <IonItemGroup>
                                                <IonItemDivider>
                                                    <IonLabel>Ratings</IonLabel>
                                                </IonItemDivider>
                                                <IonItem>
                                                    <IonLabel slot='start'>EASA rating (SPL)</IonLabel>
                                                    <IonIcon slot='end' src={selectedPilot?.easaRatingSpl ? checkbox : squareOutline} color={selectedPilot?.easaRatingSpl ? 'success' : 'none'} />
                                                </IonItem>
                                                <IonItem>
                                                    <IonLabel slot='start'>EASA rating (TMG)</IonLabel>
                                                    <IonIcon slot='end' src={selectedPilot?.easaRatingTmg ? checkbox : squareOutline} color={selectedPilot?.easaRatingTmg ? 'success' : 'none'} />
                                                </IonItem>
                                                <IonItem>
                                                    <IonLabel slot='start'>BGA badge rating (Distance)</IonLabel>
                                                    <IonLabel slot='end'>{selectedPilot?.bgaBadgeRatingDistance}</IonLabel>
                                                    {/* <IonIcon slot='end' src={selectedPilot?.bgaBadgeRatingDistance ? shieldCheckmark : ellipse} color={selectedPilot?.bgaBadgeRatingDistance ? 'success' : 'danger'} /> */}
                                                </IonItem>
                                                <IonItem>
                                                    <IonLabel slot='start'>BGA badge rating (Height)</IonLabel>
                                                    <IonLabel slot='end'>{selectedPilot?.bgaBadgeRatingHeight}</IonLabel>
                                                    {/* <IonIcon slot='end' src={selectedPilot?.bgaBadgeRatingHeight ? shieldCheckmark : ellipse} color={selectedPilot?.bgaBadgeRatingHeight ? 'success' : 'danger'} /> */}
                                                </IonItem>
                                                <IonItem>
                                                    <IonLabel slot='start'>BGA badge rating (Duration)</IonLabel>
                                                    <IonLabel slot='end'>{selectedPilot?.bgaBadgeRatingDuration}</IonLabel>
                                                    {/* <IonIcon slot='end' src={selectedPilot?.bgaBadgeRatingDuration ? shieldCheckmark : ellipse} color={selectedPilot?.bgaBadgeRatingDuration ? 'success' : 'danger'} /> */}
                                                </IonItem>
                                                <IonItem>
                                                    <IonLabel slot='start'>Overall BGA badge rating</IonLabel>
                                                    <IonLabel slot='end'>{selectedPilot?.bgaBadgeRatingOverall}</IonLabel>
                                                    {/* <IonIcon slot='end' src={selectedPilot?.bgaBadgeRatingOverall ? shieldCheckmark : ellipse} color={selectedPilot?.bgaBadgeRatingOverall ? 'success' : 'danger'} /> */}
                                                </IonItem>
                                            </IonItemGroup>
                                        </IonList>
                                    </IonCard>
                                </IonCol>
                                <IonCol size='12' sizeLg='6'>
                                    <IonCard>
                                        <IonList lines='full' inset className='cardList'>
                                            <IonItemGroup>
                                                <IonItemDivider>
                                                    <IonLabel>Certificates & Endorsements</IonLabel>
                                                </IonItemDivider>
                                                <IonItem>
                                                    <IonLabel slot='start'>Gliding certificate</IonLabel>
                                                    <IonIcon slot='end' src={selectedPilot?.glidingCertificate ? checkbox : squareOutline} color={selectedPilot?.glidingCertificate ? 'success' : 'none'} />
                                                </IonItem>
                                                <IonItem>
                                                    <IonLabel slot='start'>Cross country endorsement</IonLabel>
                                                    <IonIcon slot='end' src={selectedPilot?.crossCountryEndorsement ? checkbox : squareOutline} color={selectedPilot?.crossCountryEndorsement ? 'success' : 'none'} />
                                                </IonItem>
                                            </IonItemGroup>
                                        </IonList>
                                    </IonCard>
                                    <IonCard>
                                        <IonList lines='full' inset className='cardList'>
                                            <IonItemGroup>
                                                <IonItemDivider>
                                                    <IonLabel>Pilot Details</IonLabel>
                                                </IonItemDivider>
                                                <IonItem>
                                                    <IonLabel slot='start'>First name</IonLabel>
                                                    <IonLabel slot='end'>{selectedPilot?.firstName ?? '-'}</IonLabel>
                                                </IonItem>
                                                <IonItem>
                                                    <IonLabel slot='start'>Last name</IonLabel>
                                                    <IonLabel slot='end'>{selectedPilot?.lastName ?? '-'}</IonLabel>
                                                </IonItem>
                                            </IonItemGroup>
                                        </IonList>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                        <IonFab vertical="bottom" horizontal="end" slot="fixed">
                            <IonFabButton color='tertiary' onClick={() => setShowCreateOrEditModal(true)}>
                                <IonIcon src={pencil} size='small' />
                            </IonFabButton>
                        </IonFab>
                    </>
                }
                <CreateOrEditPilot session={session} pilotManager={pilotManager} open={showCreateOrEditModal} setOpen={setShowCreateOrEditModal} />
            </IonContent>
        </IonPage>
    );
}

export default PilotDetails;
