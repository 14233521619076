import { useEffect, useState } from "react";
import serverHelper from "../helpers/serverHelper";
import Flight from "../interfaces/Flight";
import { SessionManager } from "./useSessionManager";
import InputField from "../interfaces/InputField";
import FlightStatistics from "../interfaces/FlightStatistics";
import { json2csv } from "json-2-csv";


export interface FlightManager {
  statistics: FlightStatistics | null;
  flightFields: InputField[];
  flights: Flight[] | null;
  selectedFlight: Flight | null;
  retrieveStatistics: (userId?: string, startDate?: string, endDate?: string) => Promise<boolean>;
  retrieveFlightFields: (userId?: string) => Promise<boolean>;
  retrieveFlights: (userId?: string) => Promise<boolean>;
  addFlight: (flight: Flight) => Promise<boolean>;
  retrieveFlight: (flightId: string) => Promise<boolean>;
  updateFlight: (flight: Flight) => Promise<boolean>;
  deleteFlight: (flightId: string) => Promise<boolean>;
  clearSelectedFlight: () => void;
  flightsToCSV: () => Promise<void>;
}

function useFlightManager(session: SessionManager): FlightManager {

  // State variables
  const [flightFields, setFlightFields] = useState<InputField[]>([]);
  const [flights, setFlights] = useState<Flight[] | null>(null);
  const [selectedFlight, setSelectedFlight] = useState<Flight | null>(null);
  const [statistics, setStatistics] = useState<FlightStatistics | null>(null);

  // Clear state variables when the session is logged out
  useEffect(() => {
    if (session.authenticated === false) {
      setFlightFields([]);
      setFlights(null);
      setSelectedFlight(null);
      setStatistics(null);
    }
  }, [session.authenticated]);

  /**
   * Retrieves flight statistics from the server.
   * If no userId is provided, it uses the userId from the session.
   * @param userId - The userId to retrieve flight statistics for.
   * @param startDate - The start date of the statistics.
   * @param endDate - The end date of the statistics.
   * @sets statistics - The statistics state.
   * @returns A boolean indicating whether the retrieval was successful.
 */
  const retrieveStatistics = async (userId?: string, startDate?: string, endDate?: string) => {
    if (!userId) {
      userId = session.user?.userId;
      if (!userId) {
        return false;
      }
    }
    let serverRequest = '/flights/stats?userId=' + userId;
    if (startDate) {
      serverRequest += '&startDate=' + startDate;
    }
    if (endDate) {
      serverRequest += '&endDate=' + endDate;
    }
    try {
      const serverResponse = await serverHelper.get(serverRequest);
      if (serverResponse.ok) {
        const statistics = await serverResponse.json();
        setStatistics(statistics);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  /**
   * Retrieves flight metadata from the server.
   * If no userId is provided, it uses the userId from the session.
   * @param userId - The userId to retrieve flight metadata for.
   * @sets flightFields - The flight fields state.
   * @returns A boolean indicating whether the retrieval was successful.
   */
  const retrieveFlightFields = async (userId?: string) => {
    if (!userId) {
      userId = session.user?.userId;
      if (!userId) {
        return false;
      }
    }
    try {
      const serverResponse = await serverHelper.get('/flightMetadata?userId=' + userId);
      if (serverResponse.ok) {
        const flightMetadata = await serverResponse.json();
        setFlightFields(flightMetadata);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  /**
   * Retrieves flights from the server for a given userId.
   * If no userId is provided, it uses the userId from the session.
   * @param userId - The userId to retrieve flights for.
   * @sets flights - The flights state.
   * @returns A boolean indicating whether the retrieval was successful.
   */
  const retrieveFlights = async (userId?: string) => {
    if (!userId) {
      userId = session.user?.userId;
      if (!userId) {
        return false;
      }
    }
    try {
      const serverResponse = await serverHelper.get('/flights?userId=' + userId);
      if (serverResponse.ok) {
        const flights = await serverResponse.json();
        setFlights(flights);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  /**
   * Adds a flight to the server.
   * Will use the userId from the session if it is not provided in the flight object.
   * @param flight - The flight object to be added.
   * @sets selectedFlight - The selected flight state to the newly added flight.
   * @returns A boolean indicating whether the flight was successfully added.
   */
  const addFlight = async (flight: Flight) => {
    if (!flight.userId) {
      flight.userId = session.user?.userId as string;
      if (!flight.userId) {
        return false;
      }
    }
    try {
      const serverResponse = await serverHelper.post('/flight', flight);
      if (serverResponse.ok) {
        const newFlight = await serverResponse.json();
        setSelectedFlight(newFlight as Flight);
        retrieveFlights();
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  /**
   * Retrieves a flight from the server based on the provided ID.
   * @param flightId - The flightId of the flight to retrieve.
   * @sets selectedFlight - The selected flight state.
   * @returns A boolean indicating whether the retrieval was successful.
   */
  const retrieveFlight = async (flightId: string) => {
    try {
      const serverResponse = await serverHelper.get('/flight?flightId=' + flightId);
      if (serverResponse.ok) {
        const flight = await serverResponse.json();
        setSelectedFlight(flight as Flight);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  /**
   * Updates a flight.
   * @param flight - The flight object to be updated (must have a flightId property).
   * @updates flights - Triggers a server fetch to update the flights state.
   * @returns A boolean indicating whether the update was successful or not.
   */
  const updateFlight = async (flight: Flight) => {
    try {
      const serverResponse = await serverHelper.patch('/flight', flight);
      if (serverResponse.ok) {
        retrieveFlights();
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  /**
   * Deletes a flight.
   * @param flightId - The flightId of the flight to be deleted.
   * @updates flights - Triggers a server fetch to update the flights state.
   * @returns A boolean indicating whether the deletion was successful or not.
   */
  const deleteFlight = async (flightId: string) => {
    try {
      const serverResponse = await serverHelper.del('/flight?flightId=' + flightId);
      if (serverResponse.ok) {
        retrieveFlights();
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  /**
   * Clears the selected flight state.
   * @updates selectedFlight - Sets the selected flight state to null.
   */
  const clearSelectedFlight = () => {
    setSelectedFlight(null);
  }

  /**
   * Download flights in CSV format.
   */
  const flightsToCSV = async () => {
    if (!flights) {
      return;
    }
    // Convert flights to CSV
    const flightsCsv = await json2csv(flights, {
      emptyFieldValue: ""
    });
    // Download the CSV file
    const element = document.createElement("a");
    const file = new Blob([flightsCsv], { type: 'text/csv' });
    element.href = URL.createObjectURL(file);
    element.download = "flights.csv";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    // Clean up
    document.body.removeChild(element);
    URL.revokeObjectURL(element.href);
  }


  // Return the flight manager object
  return {
    statistics,
    flightFields,
    flights,
    selectedFlight,
    retrieveStatistics,
    retrieveFlightFields,
    retrieveFlights,
    addFlight,
    retrieveFlight,
    updateFlight,
    deleteFlight,
    clearSelectedFlight,
    flightsToCSV
  };
}

export default useFlightManager;