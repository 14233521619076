import { useEffect, useState } from "react";
import User from "../interfaces/User";
import { useIonRouter, useIonToast } from "@ionic/react";
import serverHelper from "../helpers/serverHelper";

export interface SessionManager {
  authenticated: boolean | null;
  user: User | null;
  register: (email: string, password: string) => Promise<any>;
  login: (email: string, password: string) => Promise<any>;
  logout: () => void;
}

function useSessionManager(): SessionManager {

  // Initialize the router to be used for page navigation
  const router = useIonRouter();

  // Initialize the toast to be used for displaying error messages
  const [toast] = useIonToast();

  // Initialize state variables
  const [lastAuthCheck, setLastAuthCheck] = useState<Date | null>(null);
  const [authenticated, setAuthenticated] = useState<boolean | null>(null);
  const [user, setUser] = useState<User | null>(null);

  // Check if the user is authenticated (whenever the site first loads or the current page changes)
  useEffect(() => {
    checkAuthentication();
  }, [router.routeInfo.pathname]);

  // Prevent access to certain pages based on the current authentication status (whenever an authentication check is performed)
  useEffect(() => {
    checkPageAccess();
  }, [lastAuthCheck]);

  // Check if the user is authenticated
  const checkAuthentication = async () => {
    try {
      // Get the session from the server
      const serverResponse = await serverHelper.getSession();
      if (serverResponse.ok) {
        // If the session is valid, set the authenticated user states
        const authenticatedUserJSON = await serverResponse.json();
        setUser(authenticatedUserJSON);
        setAuthenticated(true);
        console.log("User authenticated");
      } else {
        // If the session is invalid, clear the authenticated user states
        setUser(null);
        setAuthenticated(false);
        console.log("User not authenticated");
      }
    } catch (error) {
      // Catch any fetch errors
      console.error(error);
    }
    // Set the last authentication check time
    setLastAuthCheck(new Date());
  }

  // Check if the user has access to the current page
  const checkPageAccess = () => {
    console.log("Checking page access");
    // Handle the page navigation based on the authentication status
    if (authenticated === true) {
      // If the user is authenticated and is on the login / register page, redirect them to the home page
      if (router.routeInfo.pathname === "/login" || router.routeInfo.pathname === "/register") {
        router.push("/home", "root", "replace");
        console.log("Redirecting to home page");
      }
    } else if (authenticated === false) {
      // If the user is not authenticated and is not on the login / register page, redirect to the login page
      if (router.routeInfo.pathname !== "/login" && router.routeInfo.pathname !== "/register") {
        router.push("/login", "root", "replace");
        toast("You must be logged in to view this page.", 3000);
        console.log("Redirecting to login page");
      }
    }
  }

  // Register a new user
  const register = async (email: string, password: string) => {
    try {
      const response = await serverHelper.register(email, password);
      if (response.ok) {
        router.push('/login', 'none');
      } else {
        const errorResponseJSON = await response.json();
        return errorResponseJSON;
      }
    } catch (error) {
      // Catch any fetch errors
      console.error(error);
    }
  }

  // Login a user
  const login = async (email: string, password: string) => {
    try {
      const response = await serverHelper.login(email, password);
      if (response.ok) {
        router.push('/home', 'forward', 'replace');
      } else {
        const errorResponseJSON = await response.json();
        return errorResponseJSON;
      }
    } catch (error) {
      // Catch any fetch errors
      console.error(error);
    }
  }

  // Logout the current user  
  const logout = async () => {
    try {
      const response = await serverHelper.logout();
      if (response.ok) {
        router.push('/login', 'back', 'replace');
      } else {
        const errorResponseJSON = await response.json();
        return errorResponseJSON;
      }
    } catch (error) {
      // Catch any fetch errors
      console.error(error);
    }
  }

  return {
    authenticated,
    user,
    register,
    login,
    logout
  };
}

export default useSessionManager;