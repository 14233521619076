import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import LoginOrRegister from './pages/LoginOrRegister';
import Home from './pages/Home';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

/* Hooks */
import useSessionManager from './hooks/useSessionManager';
import useFlightManager from './hooks/useFlightManager';
import usePilotManager from './hooks/usePilotManager';

/* Vercel Speed Insights */
import { SpeedInsights } from '@vercel/speed-insights/react';
import { Analytics } from '@vercel/analytics/react';

setupIonicReact();

// App component (Sets up routing)
const App: React.FC = () => {
  return (
    <IonApp>
      <IonReactRouter>
        <Routing />
      </IonReactRouter>
      <SpeedInsights />
      <Analytics />
    </IonApp>
  )
};


// Routing component (Handles routing)
const Routing: React.FC = () => {

  // Initialise hooks
  const session = useSessionManager();
  const flightManager = useFlightManager(session);
  const pilotManager = usePilotManager(session);

  // Render routes
  return (
    <IonRouterOutlet>
      <Route path="/" exact={true}>
        <Redirect to="/home" />
      </Route>
      <Route path={["/login", "/register"]} exact={true}>
        <LoginOrRegister session={session} />
      </Route>
      <Route path="/home">
        <Home session={session} flightManager={flightManager} pilotManager={pilotManager} />
      </Route>
    </IonRouterOutlet>
  )
}

export default App;