import React, { useEffect } from 'react';
import { IonAlert, IonButton, IonButtons, IonContent, IonFab, IonFabButton, IonHeader, IonIcon, IonLabel, IonList, IonPage, IonProgressBar, IonRefresher, IonRefresherContent, IonTitle, IonToolbar, useIonViewDidEnter, useIonViewWillEnter } from '@ionic/react';
import './FlightList.css';
import { SessionManager } from '../hooks/useSessionManager';
import FlightListItem from '../components/FlightListItem';
import { FlightManager } from '../hooks/useFlightManager';
import { create, download, logOutOutline } from 'ionicons/icons';

interface FlightListProps {
  session: SessionManager;
  flightManager: FlightManager;
}

const FlightList: React.FC<FlightListProps> = ({ session, flightManager }) => {

  // clear the selected flight when page has entered
  useIonViewDidEnter(() => {
    flightManager.clearSelectedFlight();
  });

  // Retrieve latest flights when returning to the page (If the flights have previously been retrieved)
  useIonViewWillEnter(() => {
    if (flightManager.flights) {
      flightManager.retrieveFlights();
    }
  }, [flightManager.flights]);

  // Retrieve flights when the session userId becomes available (If the flights are not already retrieved)
  useEffect(() => {
    if (flightManager.flights === null) {
      flightManager.retrieveFlights();
    }
  }, [session.user?.userId]);

  // Retrieve flights when the list is refreshed
  const refreshList = async (e: CustomEvent) => {
    await flightManager.retrieveFlights();
    setTimeout(() => {
      e.detail.complete();
    }, 500);
  };

  return (
    <IonPage id="flight-list-tab">

      <IonHeader translucent>
        <IonToolbar>
          <IonTitle>Flight List</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => session.logout()} title='Logout'>
              <IonIcon slot="icon-only" src={logOutOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
        {flightManager.flights === null && <IonProgressBar type="indeterminate"></IonProgressBar>}
      </IonHeader>

      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={refreshList}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <IonList className='ion-no-padding'>
          {flightManager.flights?.map(flight => <FlightListItem key={flight.flightId} flightManager={flightManager} flight={flight} />)}
        </IonList>
        <IonFab vertical="bottom" horizontal="center" slot="fixed">
          <IonButton routerLink='/home/flights/new' color='tertiary' shape='round'>
            <IonIcon slot='start' src={create} style={{ fontSize: '1.2rem' }} />
            <IonLabel>New Flight</IonLabel>
          </IonButton>
        </IonFab>
        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton id='download-csv-btn' size="small" color='tertiary'>
            <IonIcon src={download} size='small' />
          </IonFabButton>
        </IonFab>
        <IonAlert
          trigger='download-csv-btn'
          header='Download Flights CSV'
          message='Are you sure you want to download all flights in CSV format?'
          buttons={[
            { text: 'Cancel', role: 'cancel' },
            { text: 'Download', role: 'confirm', handler: () => flightManager.flightsToCSV() }
          ]}
        />
      </IonContent>

    </IonPage>
  );
};

export default FlightList;
