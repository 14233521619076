import React, { useEffect, useState } from 'react';
import { IonButton, IonButtons, IonCard, IonCardHeader, IonCardTitle, IonCol, IonContent, IonDatetime, IonDatetimeButton, IonGrid, IonHeader, IonIcon, IonItem, IonItemDivider, IonItemGroup, IonLabel, IonList, IonModal, IonPage, IonProgressBar, IonRefresher, IonRefresherContent, IonRow, IonTitle, IonToolbar, useIonViewWillEnter } from '@ionic/react';
import { Duration, DateTime } from "luxon";
import '../styles/SharedStyles.css';
import './FlightStats.css';
import { FlightManager } from '../hooks/useFlightManager';
import { logOutOutline } from 'ionicons/icons';
import { SessionManager } from '../hooks/useSessionManager';

interface FlightStatsProps {
  session: SessionManager;
  flightManager: FlightManager;
}

const FlightStats: React.FC<FlightStatsProps> = ({ session, flightManager }) => {

  // Initialise states
  const [selectedStartDate, setSelectedStartDate] = useState<string>(DateTime.now().minus({ years: 1 }).toISODate());
  const [selectedEndDate, setSelectedEndDate] = useState<string>(DateTime.now().toISODate());

  // Retrieve latest flight stats when returning to the page (if the stats are already retrieved)
  useIonViewWillEnter(() => {
    if (flightManager.statistics) {
      flightManager.retrieveStatistics(undefined, selectedStartDate, selectedEndDate);
    }
  }, [flightManager.statistics]);

  // Retrieve flight stats when the session userId becomes available (if the stats are not already retrieved)
  useEffect(() => {
    if (flightManager.statistics === null) {
      flightManager.retrieveStatistics(undefined, selectedStartDate, selectedEndDate);
    }
  }, [session.user?.userId]);

  // Retrieve flight statistics when the selected start date or end date changes (if the statistics are already retrieved)
  useEffect(() => {
    if (flightManager.statistics) {
      flightManager.retrieveStatistics(undefined, selectedStartDate, selectedEndDate);
    }
  }, [selectedStartDate, selectedEndDate]);

  // Retrieve stats when the page is refreshed
  const refreshStats = async (e: CustomEvent) => {
    await flightManager.retrieveStatistics(undefined, selectedStartDate, selectedEndDate);
    setTimeout(() => {
      e.detail.complete();
    }, 500);
  };

  // Get statistics from flight manager
  const statistics = flightManager.statistics;

  return (
    <IonPage id="flight-stats-tab">

      <IonHeader translucent>
        <IonToolbar>
          <IonTitle>Statistics</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => session.logout()} title='Logout'>
              <IonIcon slot="icon-only" src={logOutOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
        {flightManager.statistics === null && <IonProgressBar type="indeterminate"></IonProgressBar>}
      </IonHeader>

      <IonContent className='frostedGlassContent'>
        <IonRefresher slot="fixed" onIonRefresh={refreshStats}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        {statistics &&
          <>
            <IonGrid className='rootContentGrid'>
              <IonRow>
                <IonCol size='12'>
                  <IonCard>
                    <IonGrid>
                      <IonRow className='ion-justify-content-center'>
                        <IonCol className='centeredColContent'>
                          <IonLabel className='dateLabel'>From</IonLabel>
                          <IonDatetimeButton datetime="datetimeStart"></IonDatetimeButton>
                          <IonModal keepContentsMounted={true}>
                            <IonDatetime id="datetimeStart" presentation={'date'} value={selectedStartDate} onIonChange={e => setSelectedStartDate(e.detail.value! as string)} firstDayOfWeek={1} mode={'ios'} />
                          </IonModal>
                        </IonCol>
                        <IonCol className='centeredColContent'>
                          <IonLabel className='dateLabel'>To</IonLabel>
                          <IonDatetimeButton datetime="datetimeEnd"></IonDatetimeButton>
                          <IonModal keepContentsMounted={true}>
                            <IonDatetime id="datetimeEnd" presentation={'date'} value={selectedEndDate} onIonChange={e => setSelectedEndDate(e.detail.value! as string)} firstDayOfWeek={1} mode={'ios'} />
                          </IonModal>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCard>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size='12' sizeLg='6'>
                  <IonCard>
                    <IonCardHeader className='noBottomPadding'>
                      <IonCardTitle>General Stats</IonCardTitle>
                    </IonCardHeader>
                    <IonList lines='full' inset className='cardList'>
                      <IonItemGroup>
                        <IonItemDivider>
                          <IonLabel>Flight Stats</IonLabel>
                        </IonItemDivider>
                        <IonItem>
                          <IonLabel slot='start'>Total Flights</IonLabel>
                          <IonLabel slot='end'>{statistics?.flights ? statistics?.flights : '-'}</IonLabel>
                        </IonItem>
                        <IonItem>
                          <IonLabel slot='start'>Last Flight</IonLabel>
                          <IonLabel slot='end'>{statistics?.lastFlight ? DateTime.fromISO(statistics?.lastFlight).toRelative({ unit: ['years', 'months', 'days'] }) : '-'}</IonLabel>
                        </IonItem>
                        <IonItem>
                          <IonLabel slot='start'>Time as Person In Charge</IonLabel>
                          <IonLabel slot='end'>{statistics?.durationAsPersonInCharge ? Duration.fromMillis(statistics?.durationAsPersonInCharge).toFormat('h \'hrs\' m \'mins\'') : '-'}</IonLabel>
                        </IonItem>
                        <IonItem>
                          <IonLabel slot='start'>Average Flight Duration</IonLabel>
                          <IonLabel slot='end'>{statistics?.averageFlightDuration ? Duration.fromMillis(statistics?.averageFlightDuration).toFormat('h \'hrs\' m \'mins\'') : '-'}</IonLabel>
                        </IonItem>
                        <IonItem>
                          <IonLabel slot='start'>Total XC Distance</IonLabel>
                          <IonLabel slot='end'>{statistics?.totalXcDistance ? statistics?.totalXcDistance : '-'} km</IonLabel>
                        </IonItem>
                        <IonItem>
                          <IonLabel slot='start'>Max Height Gain</IonLabel>
                          <IonLabel slot='end'>{statistics?.maxHeightGain ? statistics?.maxHeightGain : '-'} ft</IonLabel>
                        </IonItem>
                      </IonItemGroup>
                    </IonList>
                  </IonCard>
                  <IonCard>
                    <IonCardHeader className='noBottomPadding'>
                      <IonCardTitle>Flight Role Stats</IonCardTitle>
                    </IonCardHeader>
                    <IonList lines='full' inset className='cardList'>
                      <IonItemGroup>
                        <IonItemDivider>
                          <IonLabel>Flight Count By Role</IonLabel>
                        </IonItemDivider>
                        <IonItem>
                          <IonLabel slot='start'>Solo Flights</IonLabel>
                          <IonLabel slot='end'>{statistics?.flightsSolo ? statistics?.flightsSolo : '-'}</IonLabel>
                        </IonItem>
                        <IonItem>
                          <IonLabel slot='start'>Flights as P1</IonLabel>
                          <IonLabel slot='end'>{statistics?.flightsAsP1 ? statistics?.flightsAsP1 : '-'}</IonLabel>
                        </IonItem>
                        <IonItem>
                          <IonLabel slot='start'>Flights as P2</IonLabel>
                          <IonLabel slot='end'>{statistics?.flightsAsP2 ? statistics?.flightsAsP2 : '-'}</IonLabel>
                        </IonItem>
                        <IonItem>
                          <IonLabel slot='start'>Flights Under Instruction</IonLabel>
                          <IonLabel slot='end'>{statistics?.flightsUnderInstruction ? statistics?.flightsUnderInstruction : '-'}</IonLabel>
                        </IonItem>
                        <IonItem>
                          <IonLabel slot='start'>Flights Instructing</IonLabel>
                          <IonLabel slot='end'>{statistics?.flightsInstructing ? statistics?.flightsInstructing : '-'}</IonLabel>
                        </IonItem>
                      </IonItemGroup>
                    </IonList>
                    <IonList lines='full' inset className='cardList'>
                      <IonItemGroup>
                        <IonItemDivider>
                          <IonLabel>Total Flight Duration By Role</IonLabel>
                        </IonItemDivider>
                        <IonItem>
                          <IonLabel slot='start'>Duration Solo</IonLabel>
                          <IonLabel slot='end'>{statistics?.durationSolo ? Duration.fromMillis(statistics?.durationSolo).toFormat('h \'hrs\' m \'mins\'') : '-'}</IonLabel>
                        </IonItem>
                        <IonItem>
                          <IonLabel slot='start'>Duration as P1</IonLabel>
                          <IonLabel slot='end'>{statistics?.durationAsP1 ? Duration.fromMillis(statistics?.durationAsP1).toFormat('h \'hrs\' m \'mins\'') : '-'}</IonLabel>
                        </IonItem>
                        <IonItem>
                          <IonLabel slot='start'>Duration as P2</IonLabel>
                          <IonLabel slot='end'>{statistics?.durationAsP2 ? Duration.fromMillis(statistics?.durationAsP2).toFormat('h \'hrs\' m \'mins\'') : '-'}</IonLabel>
                        </IonItem>
                        <IonItem>
                          <IonLabel slot='start'>Duration Under Instruction</IonLabel>
                          <IonLabel slot='end'>{statistics?.durationUnderInstruction ? Duration.fromMillis(statistics?.durationUnderInstruction).toFormat('h \'hrs\' m \'mins\'') : '-'}</IonLabel>
                        </IonItem>
                        <IonItem>
                          <IonLabel slot='start'>Duration Instructing</IonLabel>
                          <IonLabel slot='end'>{statistics?.durationInstructing ? Duration.fromMillis(statistics?.durationInstructing).toFormat('h \'hrs\' m \'mins\'') : '-'}</IonLabel>
                        </IonItem>
                      </IonItemGroup>
                    </IonList>
                    <IonList lines='full' inset className='cardList'>
                      <IonItemGroup>
                        <IonItemDivider>
                          <IonLabel>Time Since Last Flight by Role</IonLabel>
                        </IonItemDivider>
                        <IonItem>
                          <IonLabel slot='start'>Last Solo Flight</IonLabel>
                          <IonLabel slot='end'>{statistics?.lastSoloFlight ? DateTime.fromISO(statistics?.lastSoloFlight).toRelative({ unit: ['years', 'months', 'days'] }) : '-'}</IonLabel>
                        </IonItem>
                        <IonItem>
                          <IonLabel slot='start'>Last Flight as P1</IonLabel>
                          <IonLabel slot='end'>{statistics?.lastFlightAsP1 ? DateTime.fromISO(statistics?.lastFlightAsP1).toRelative({ unit: ['years', 'months', 'days'] }) : '-'}</IonLabel>
                        </IonItem>
                        <IonItem>
                          <IonLabel slot='start'>Last Flight as P2</IonLabel>
                          <IonLabel slot='end'>{statistics?.lastFlightAsP2 ? DateTime.fromISO(statistics?.lastFlightAsP2).toRelative({ unit: ['years', 'months', 'days'] }) : '-'}</IonLabel>
                        </IonItem>
                        <IonItem>
                          <IonLabel slot='start'>Last Flight Under Instruction</IonLabel>
                          <IonLabel slot='end'>{statistics?.lastFlightUnderInstruction ? DateTime.fromISO(statistics?.lastFlightUnderInstruction).toRelative({ unit: ['years', 'months', 'days'] }) : '-'}</IonLabel>
                        </IonItem>
                        <IonItem>
                          <IonLabel slot='start'>Last Flight Instructing</IonLabel>
                          <IonLabel slot='end'>{statistics?.lastFlightInstructing ? DateTime.fromISO(statistics?.lastFlightInstructing).toRelative({ unit: ['years', 'months', 'days'] }) : '-'}</IonLabel>
                        </IonItem>
                      </IonItemGroup>
                    </IonList>
                  </IonCard>
                </IonCol>
                <IonCol size='12' sizeLg='6'>
                  <IonCard>
                    <IonCardHeader className='noBottomPadding'>
                      <IonCardTitle>Launch Type Stats</IonCardTitle>
                    </IonCardHeader>
                    <IonList lines='full' inset className='cardList'>
                      <IonItemGroup>
                        <IonItemDivider>
                          <IonLabel>Flight Count By Launch Type</IonLabel>
                        </IonItemDivider>
                        <IonItem>
                          <IonLabel slot='start'>Winch Launches</IonLabel>
                          <IonLabel slot='end'>{statistics?.winchLaunches ? statistics?.winchLaunches : '-'}</IonLabel>
                        </IonItem>
                        <IonItem>
                          <IonLabel slot='start'>Aerotow Launches</IonLabel>
                          <IonLabel slot='end'>{statistics?.aerotowLaunches ? statistics?.aerotowLaunches : '-'}</IonLabel>
                        </IonItem>
                        <IonItem>
                          <IonLabel slot='start'>Self Launches</IonLabel>
                          <IonLabel slot='end'>{statistics?.selfLaunches ? statistics?.selfLaunches : '-'}</IonLabel>
                        </IonItem>
                        <IonItem>
                          <IonLabel slot='start'>Bungee Launches</IonLabel>
                          <IonLabel slot='end'>{statistics?.bungeeLaunches ? statistics?.bungeeLaunches : '-'}</IonLabel>
                        </IonItem>
                      </IonItemGroup>
                    </IonList>
                    <IonList lines='full' inset className='cardList'>
                      <IonItemGroup>
                        <IonItemDivider>
                          <IonLabel>Time Since Last Launch Type</IonLabel>
                        </IonItemDivider>
                        <IonItem>
                          <IonLabel slot='start'>Last Winch Launch</IonLabel>
                          <IonLabel slot='end'>{statistics?.lastWinchLaunch ? DateTime.fromISO(statistics?.lastWinchLaunch).toRelative({ unit: ['years', 'months', 'days'] }) : '-'}</IonLabel>
                        </IonItem>
                        <IonItem>
                          <IonLabel slot='start'>Last Aerotow Launch</IonLabel>
                          <IonLabel slot='end'>{statistics?.lastAerotowLaunch ? DateTime.fromISO(statistics?.lastAerotowLaunch).toRelative({ unit: ['years', 'months', 'days'] }) : '-'}</IonLabel>
                        </IonItem>
                        <IonItem>
                          <IonLabel slot='start'>Last Self Launch</IonLabel>
                          <IonLabel slot='end'>{statistics?.lastSelfLaunch ? DateTime.fromISO(statistics?.lastSelfLaunch).toRelative({ unit: ['years', 'months', 'days'] }) : '-'}</IonLabel>
                        </IonItem>
                        <IonItem>
                          <IonLabel slot='start'>Last Bungee Launch</IonLabel>
                          <IonLabel slot='end'>{statistics?.lastBungeeLaunch ? DateTime.fromISO(statistics?.lastBungeeLaunch).toRelative({ unit: ['years', 'months', 'days'] }) : '-'}</IonLabel>
                        </IonItem>
                      </IonItemGroup>
                    </IonList>
                  </IonCard>
                  <IonCard>
                    <IonCardHeader className='noBottomPadding'>
                      <IonCardTitle>Flight Event Stats</IonCardTitle>
                    </IonCardHeader>
                    <IonList lines='full' inset className='cardList'>
                      <IonItemGroup>
                        <IonItemDivider>
                          <IonLabel>Flight Count By Event</IonLabel>
                        </IonItemDivider>
                        <IonItem>
                          <IonLabel slot='start'>Check Flights</IonLabel>
                          <IonLabel slot='end'>{statistics?.checkFlights ? statistics?.checkFlights : '-'}</IonLabel>
                        </IonItem>
                        <IonItem>
                          <IonLabel slot='start'>Stall Exercises</IonLabel>
                          <IonLabel slot='end'>{statistics?.stallExercises ? statistics?.stallExercises : '-'}</IonLabel>
                        </IonItem>
                        <IonItem>
                          <IonLabel slot='start'>Spin Exercises</IonLabel>
                          <IonLabel slot='end'>{statistics?.spinExercises ? statistics?.spinExercises : '-'}</IonLabel>
                        </IonItem>
                        <IonItem>
                          <IonLabel slot='start'>Line Breaks</IonLabel>
                          <IonLabel slot='end'>{statistics?.lineBreaks ? statistics?.lineBreaks : '-'}</IonLabel>
                        </IonItem>
                        <IonItem>
                          <IonLabel slot='start'>Land Outs</IonLabel>
                          <IonLabel slot='end'>{statistics?.landOuts ? statistics?.landOuts : '-'}</IonLabel>
                        </IonItem>
                        <IonItem>
                          <IonLabel slot='start'>Soaring Flights</IonLabel>
                          <IonLabel slot='end'>{statistics?.soaringFlights ? statistics?.soaringFlights : '-'}</IonLabel>
                        </IonItem>
                        <IonItem>
                          <IonLabel slot='start'>Friends & Family Flights</IonLabel>
                          <IonLabel slot='end'>{statistics?.friendsAndFamilyFlights ? statistics?.friendsAndFamilyFlights : '-'}</IonLabel>
                        </IonItem>
                      </IonItemGroup>
                    </IonList>
                    <IonList lines='full' inset className='cardList'>
                      <IonItemGroup>
                        <IonItemDivider>
                          <IonLabel>Time Since Last Event</IonLabel>
                        </IonItemDivider>
                        <IonItem>
                          <IonLabel slot='start'>Last Check Flight</IonLabel>
                          <IonLabel slot='end'>{statistics?.lastCheckFlight ? DateTime.fromISO(statistics?.lastCheckFlight).toRelative({ unit: ['years', 'months', 'days'] }) : '-'}</IonLabel>
                        </IonItem>
                        <IonItem>
                          <IonLabel slot='start'>Last Stall Exercise</IonLabel>
                          <IonLabel slot='end'>{statistics?.lastStallExercise ? DateTime.fromISO(statistics?.lastStallExercise).toRelative({ unit: ['years', 'months', 'days'] }) : '-'}</IonLabel>
                        </IonItem>
                        <IonItem>
                          <IonLabel slot='start'>Last Spin Exercise</IonLabel>
                          <IonLabel slot='end'>{statistics?.lastSpinExercise ? DateTime.fromISO(statistics?.lastSpinExercise).toRelative({ unit: ['years', 'months', 'days'] }) : '-'}</IonLabel>
                        </IonItem>
                        <IonItem>
                          <IonLabel slot='start'>Last Line Break</IonLabel>
                          <IonLabel slot='end'>{statistics?.lastLineBreak ? DateTime.fromISO(statistics?.lastLineBreak).toRelative({ unit: ['years', 'months', 'days'] }) : '-'}</IonLabel>
                        </IonItem>
                        <IonItem>
                          <IonLabel slot='start'>Last Land Out</IonLabel>
                          <IonLabel slot='end'>{statistics?.lastLandOut ? DateTime.fromISO(statistics?.lastLandOut).toRelative({ unit: ['years', 'months', 'days'] }) : '-'}</IonLabel>
                        </IonItem>
                        <IonItem>
                          <IonLabel slot='start'>Last Soaring Flight</IonLabel>
                          <IonLabel slot='end'>{statistics?.lastSoaringFlight ? DateTime.fromISO(statistics?.lastSoaringFlight).toRelative({ unit: ['years', 'months', 'days'] }) : '-'}</IonLabel>
                        </IonItem>
                        <IonItem>
                          <IonLabel slot='start'>Last Friends & Family Flight</IonLabel>
                          <IonLabel slot='end'>{statistics?.lastFriendsAndFamilyFlight ? DateTime.fromISO(statistics?.lastFriendsAndFamilyFlight).toRelative({ unit: ['years', 'months', 'days'] }) : '-'}</IonLabel>
                        </IonItem>
                      </IonItemGroup>
                    </IonList>
                  </IonCard>
                </IonCol>
              </IonRow>
            </IonGrid>
          </>
        }
      </IonContent>

    </IonPage>
  );
}

export default FlightStats;

