import React, { useState } from 'react';
import { IonButton, IonContent, IonInput, IonLoading, IonPage, useIonToast } from '@ionic/react';
import backgroundImg from '../assets/background.png';
import logo from '../assets/icon.webp';
import './LoginOrRegister.css';
import { Route } from 'react-router';
import { SessionManager } from '../hooks/useSessionManager';

interface LoginOrRegisterProps {
  session: SessionManager;
}

const LoginOrRegister: React.FC<LoginOrRegisterProps> = ({ session }) => {
  return (
    <IonPage id="login-page">
      <IonContent fullscreen>
        <div className="loginFrame">
          <div className="loginContainer">
            <img className="icon" src={logo} alt="icon" />
            <Route path="/login" exact={true}>
              <Login session={session} />
            </Route>
            <Route path="/register" exact={true}>
              <Register session={session} />
            </Route>
          </div>
        </div>
        <img className="backgroundImg" src={backgroundImg} alt="background" />
      </IonContent>
    </IonPage>
  );
};

const Login: React.FC<LoginOrRegisterProps> = ({ session }) => {

  const [present] = useIonToast();

  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string>();

  const [loading, setLoading] = useState<boolean>(false);

  const handleLogin = async () => {
    if (email && password) {
      setLoading(true);
      const errorResponse = await session.login(email, password)
      setLoading(false);
      if (errorResponse) {
        present({ message: errorResponse.message, color: 'danger', duration: 5000 });
      }
    } else {
      present({ message: 'Please enter an email and password', color: 'danger', duration: 5000 });
    }
  };

  return (
    <>
      <div className="loginInputs">
        <IonInput label="Email" placeholder="Email" type="email" autocomplete='email' onIonInput={e => setEmail(e.detail.value || '')}></IonInput>
        <IonInput label="Password" placeholder="Password" type="password" autocomplete='current-password' onIonInput={e => setPassword(e.detail.value || '')}></IonInput>
      </div>
      <div className="loginButtons">
        <IonButton className="loginButton" color="primary" shape='round' expand="block" onClick={() => handleLogin()}>Login</IonButton>
        <IonButton className="loginButton" color="primary" shape='round' fill='outline' expand="block" routerLink="/register" routerDirection='none'>Register</IonButton>
      </div>
      <IonLoading
        isOpen={loading}
        onDidDismiss={() => setLoading(false)}
        message={'Logging in...'}
      />
    </>
  )
}

const Register: React.FC<LoginOrRegisterProps> = ({ session }) => {

  const [present] = useIonToast();

  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [confirmPassword, setConfirmPassword] = useState<string>();

  const handleRegister = async () => {
    if (email && password && confirmPassword) {
      if (password === confirmPassword) {
        const errorResponse = await session.register(email, password);
        if (errorResponse) {
          present({ message: errorResponse.message, color: 'danger', duration: 5000 });
        } else {
          present({ message: 'Account created successfully, please login', color: 'success', duration: 5000 });
        }
      } else {
        present({ message: 'Passwords do not match', color: 'danger', duration: 5000 });
      }
    } else {
      present({ message: 'Please enter values for all fields', color: 'danger', duration: 5000 });
    }
  }

  return (
    <>
      <div className="loginInputs">
        <IonInput label="Email" placeholder="Email" type="email" autocomplete='email' onIonInput={e => setEmail(e.detail.value || '')}></IonInput>
        <IonInput label="Password" placeholder="Password" type="password" autocomplete='new-password' onIonInput={e => setPassword(e.detail.value || '')}></IonInput>
        <IonInput label="Confirm Password" placeholder="Password" type="password" autocomplete='new-password' onIonInput={e => setConfirmPassword(e.detail.value || '')} onKeyDown={e => { if (e.key === 'Enter') handleRegister() }}></IonInput>
      </div>
      <div className="loginButtons">
        <IonButton className="loginButton" color="primary" shape='round' expand="block" onClick={() => handleRegister()}>Create Account</IonButton>
        <IonButton className="loginButton" color="primary" shape='round' fill='outline' expand="block" routerLink="/login" routerDirection='none'>Already Registered?</IonButton>
      </div>
    </>
  )
}

export default LoginOrRegister;
