import React, { useEffect } from 'react';
import { SessionManager } from '../hooks/useSessionManager';
import { IonButton, IonButtons, IonCard, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonItemDivider, IonItemGroup, IonLabel, IonList, IonPage, IonProgressBar, IonRefresher, IonRefresherContent, IonRow, IonTitle, IonToolbar, useIonViewWillEnter } from '@ionic/react';
import { checkbox, ellipse, logOutOutline, pencil, shieldCheckmark, squareOutline } from 'ionicons/icons';
import '../styles/SharedStyles.css';
import './FlightStats.css';
import CreateOrEditPilot from '../components/CreateOrEditPilot';
import { PilotManager } from '../hooks/usePilotManager';
import { DateTime, Duration } from 'luxon';

interface CurrencyDetailsProps {
  session: SessionManager;
  pilotManager: PilotManager;
}

const CurrencyDetails: React.FC<CurrencyDetailsProps> = ({ session, pilotManager }) => {

  // Retrieve current pilot details when returning to the page (If the details have previously been retrieved)
  useIonViewWillEnter(() => {
    if (pilotManager.selectedPilot) {
      pilotManager.retrievePilotCurrency();
    }
  }, [pilotManager.selectedPilot]);

  // Retrieve current pilot details when the session userId becomes available (If the details are not already retrieved)
  useEffect(() => {
    if (pilotManager.selectedPilot === null) {
      pilotManager.retrievePilotCurrency();
    }
  }, [session.user?.userId]);

  // Retrieve current pilot details when the list is refreshed
  const refreshCurrencyDetails = async (e: CustomEvent) => {
    await pilotManager.retrievePilotCurrency();
    setTimeout(() => {
      e.detail.complete();
    }, 500);
  };

  // Get selected flight from flight manager
  const pilotCurrency = pilotManager.pilotCurrency;

  return (
    <IonPage id="view-flight-page">

      <IonHeader translucent>
        <IonToolbar>
          <IonTitle>Currency (Beta)</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => session.logout()} title='Logout'>
              <IonIcon slot="icon-only" src={logOutOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
        {pilotCurrency === null && pilotManager.pilotCreated && <IonProgressBar type="indeterminate"></IonProgressBar>}
      </IonHeader>

      <IonContent className='frostedGlassContent'>
        <IonRefresher slot="fixed" onIonRefresh={refreshCurrencyDetails}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        {pilotCurrency &&
          <>
            <IonGrid className='rootContentGrid'>
              <IonRow>
                <IonCol size='12' sizeLg='6'>
                  <IonCard>
                    <IonList lines='full' inset className='cardList'>
                      <IonItemGroup>
                        <IonItemDivider>
                          <IonLabel>Currency Overview</IonLabel>
                        </IonItemDivider>
                        <IonItem>
                          <IonLabel slot='start'>BGA Currency</IonLabel>
                          <IonLabel slot='end'>{pilotCurrency?.bgaCurrency}</IonLabel>
                          <IonIcon slot='end' src={ellipse} color={pilotCurrency?.bgaCurrency == "Red" ? 'danger' : pilotCurrency?.bgaCurrency == "Amber" ? 'warning' : 'success'} />
                        </IonItem>
                        <IonItem>
                          <IonLabel slot='start'>EASA Passenger Currency</IonLabel>
                          <IonLabel slot='end'>{pilotCurrency?.easaPassengerCurrency}</IonLabel>
                          <IonIcon slot='end' src={ellipse} color={pilotCurrency?.easaPassengerCurrency == "Red" ? 'danger' : pilotCurrency?.easaPassengerCurrency == "Amber" ? 'warning' : 'success'} />
                        </IonItem>
                        <IonItem>
                          <IonLabel slot='start'>Passenger Currency (30 day)</IonLabel>
                          <IonLabel slot='end'>{pilotCurrency?.passengerCurrency30d}</IonLabel>
                          <IonIcon slot='end' src={ellipse} color={pilotCurrency?.passengerCurrency30d == "Red" ? 'danger' : pilotCurrency?.passengerCurrency30d == "Amber" ? 'warning' : 'success'} />
                        </IonItem>
                        <IonItem>
                          <IonLabel slot='start'>Six Week Launch Currency (Aerotow)</IonLabel>
                          <IonLabel slot='end'>{pilotCurrency?.sixWeekAerotowLaunchCurrency}</IonLabel>
                          <IonIcon slot='end' src={ellipse} color={pilotCurrency?.sixWeekAerotowLaunchCurrency == "Red" ? 'danger' : pilotCurrency?.sixWeekAerotowLaunchCurrency == "Amber" ? 'warning' : 'success'} />
                        </IonItem>
                        <IonItem>
                          <IonLabel slot='start'>Six Week Launch Currency (Winch)</IonLabel>
                          <IonLabel slot='end'>{pilotCurrency?.sixWeekWinchLaunchCurrency}</IonLabel>
                          <IonIcon slot='end' src={ellipse} color={pilotCurrency?.sixWeekWinchLaunchCurrency == "Red" ? 'danger' : pilotCurrency?.sixWeekWinchLaunchCurrency == "Amber" ? 'warning' : 'success'} />
                        </IonItem>
                      </IonItemGroup>
                    </IonList>
                  </IonCard>
                </IonCol>
                <IonCol size='12' sizeLg='6'>
                  <IonCard>
                    <IonList lines='full' inset className='cardList'>
                      <IonItemGroup>
                        <IonItemDivider>
                          <IonLabel>Solo / P1 / Instructing Stats (Last 12 months)</IonLabel>
                        </IonItemDivider>
                        <IonItem>
                          <IonLabel slot='start'>Total Launches</IonLabel>
                          <IonLabel slot='end'>{pilotCurrency?.totalLaunches ? pilotCurrency?.totalLaunches : '-'}</IonLabel>
                        </IonItem>
                        <IonItem>
                          <IonLabel slot='start'>Winch Launches</IonLabel>
                          <IonLabel slot='end'>{pilotCurrency?.winchLaunches ? pilotCurrency?.winchLaunches : '-'}</IonLabel>
                        </IonItem>
                        <IonItem>
                          <IonLabel slot='start'>Aerotow Launches</IonLabel>
                          <IonLabel slot='end'>{pilotCurrency?.aerotowLaunches ? pilotCurrency?.aerotowLaunches : '-'}</IonLabel>
                        </IonItem>
                        <IonItem>
                          <IonLabel slot='start'>Last Check Flight</IonLabel>
                          <IonLabel slot='end'>{pilotCurrency?.lastCheckFlight ? DateTime.fromISO(pilotCurrency?.lastCheckFlight).toRelative({ unit: ['years', 'months', 'days'] }) : '-'}</IonLabel>
                        </IonItem>
                        <IonItem>
                          <IonLabel slot='start'>Flights Since Last Check Flight</IonLabel>
                          <IonLabel slot='end'>{pilotCurrency?.flightsSinceLastCheckFlight ? pilotCurrency?.flightsSinceLastCheckFlight : '-'}</IonLabel>
                        </IonItem>
                        <IonItem>
                          <IonLabel slot='start'>Average Flight Duration</IonLabel>
                          <IonLabel slot='end'>{pilotCurrency?.averageFlightDuration ? Duration.fromMillis(pilotCurrency?.averageFlightDuration).toFormat('h \'hrs\' m \'mins\'') : '-'}</IonLabel>
                        </IonItem>
                        <IonItem>
                          <IonLabel slot='start'>Duration as P1 / Solo / Instructing</IonLabel>
                          <IonLabel slot='end'>{pilotCurrency?.durationAsP1OrSoloOrInstructing ? Duration.fromMillis(pilotCurrency?.durationAsP1OrSoloOrInstructing).toFormat('h \'hrs\' m \'mins\'') : '-'}</IonLabel>
                        </IonItem>
                        <IonItem>
                          <IonLabel slot='start'>Duration as P2 / Under Instruction</IonLabel>
                          <IonLabel slot='end'>{pilotCurrency?.durationAsP2OrUnderInstruction ? Duration.fromMillis(pilotCurrency?.durationAsP2OrUnderInstruction).toFormat('h \'hrs\' m \'mins\'') : '-'}</IonLabel>
                        </IonItem>
                      </IonItemGroup>
                    </IonList>
                  </IonCard>
                </IonCol>
              </IonRow>
            </IonGrid>
          </>
        }
      </IonContent>
    </IonPage>
  );
}

export default CurrencyDetails;
