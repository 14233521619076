import { useEffect, useState } from "react";
import InputField from "../interfaces/InputField";

export interface InputListManager {
    inputFields: InputField[];

    inputData: any;
    setInputData: (data: any) => void;
    updateInputData: (key: string, value: any) => void;

    showErrors: boolean;
    setShowErrors: (showErrors: boolean) => void;

    requiredFieldsFilled: () => boolean;
    requiredFieldFilled: (field: InputField) => boolean;
}

function useInputListManager(inputFields: InputField[], sourceData: any): InputListManager {

    // Initialise states
    const [inputData, setInputData] = useState<{ [key: string]: any }>({});
    const [showErrors, setShowErrors] = useState(false);

    // When the source data changes, update the input data to match
    useEffect(() => {
        if (sourceData) {
            setInputData(sourceData);
        }
    }, [sourceData]);

    // Method to add / update an entry within the input data
    const updateInputData = (key: string, value: any) => {
        setInputData({ ...inputData, [key]: value });
    };

    // Checks if all required fields are filled in (returns true if all required fields are filled in)
    const requiredFieldsFilled = () => {
        for (const field of inputFields) {
            if (!requiredFieldFilled(field)) {
                return false;
            }
        }
        return true;
    };

    // Checks if the specified required field is filled in (returns true if the field is filled in)
    const requiredFieldFilled = (field: InputField) => {
        if (field.required && !inputData[field.id]) {
            return false;
        }
        return true;
    };

    return {
        inputFields,
        inputData,
        setInputData,
        updateInputData,
        showErrors,
        setShowErrors,
        requiredFieldsFilled,
        requiredFieldFilled
    };
}

export default useInputListManager;