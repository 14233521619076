import React from 'react';
import { IonBackButton, IonButton, IonButtons, IonCard, IonCol, IonContent, IonFab, IonFabButton, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonPage, IonProgressBar, IonRefresher, IonRefresherContent, IonRow, IonTitle, IonToolbar, useIonViewWillEnter } from '@ionic/react';
import { useParams } from 'react-router';
import { Duration, DateTime } from "luxon";
import '../styles/SharedStyles.css';
import './ViewFlight.css';
import { FlightManager } from '../hooks/useFlightManager';
import CreateOrEditFlight from '../components/CreateOrEditFlight';
import { airplaneOutline, checkbox, logOutOutline, pencil, squareOutline } from 'ionicons/icons';
import gliderImage from '../assets/glider.webp';
import { SessionManager } from '../hooks/useSessionManager';

interface ViewFlightProps {
  session: SessionManager;
  flightManager: FlightManager;
}

const ViewFlight: React.FC<ViewFlightProps> = ({ session, flightManager }) => {

  // Get flight ID from URL
  const params = useParams<{ id: string, action: string }>();

  // Initialise states
  const [showCreateOrEditModal, setShowCreateOrEditModal] = React.useState(false);

  // On page load
  useIonViewWillEnter(() => {
    // Check if flight ID is present in URL
    if (params.id) {
      // If URL value is 'new' show create flight modal, otherwise retrieve flight
      if (params.id === 'new') {
        setShowCreateOrEditModal(true);
      } else {
        flightManager.retrieveFlight(params.id);
        // Show edit flight modal if action is edit
        if (params.action === 'edit') {
          setShowCreateOrEditModal(true);
        }
      }
    }
  });

  // Retrieve selected flight when the page is refreshed
  const refreshSelectedFlight = async (e: CustomEvent) => {
    await flightManager.retrieveFlight(params.id);
    setTimeout(() => {
      e.detail.complete();
    }, 500);
  };

  // Get selected flight from flight manager
  const selectedFlight = flightManager.selectedFlight;

  return (
    <IonPage id="view-flight-page">

      <IonHeader translucent>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/home"></IonBackButton>
          </IonButtons>
          <IonTitle>Flight {selectedFlight?.flightNumber}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => session.logout()} title='Logout'>
              <IonIcon slot="icon-only" src={logOutOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
        {(flightManager.selectedFlight === null && params.id !== 'new') ? <IonProgressBar type="indeterminate"></IonProgressBar> : null}
      </IonHeader>

      <IonContent className='frostedGlassContent'>
        <IonRefresher slot="fixed" onIonRefresh={refreshSelectedFlight}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        {selectedFlight &&
          <>
            <IonGrid className='rootContentGrid'>
              <IonRow>
                <IonCol size='12'>
                  <IonCard>
                    <IonGrid>
                      <IonRow className='ion-justify-content-center'>
                        <IonCol className='centeredColContent'>
                          <IonIcon src={airplaneOutline} style={{ transform: 'rotatez(-25deg) rotatex(-30deg)', fontSize: '2.5rem' }} color='success' />
                          <span style={{ marginTop: '0.2rem' }}>{selectedFlight?.takeoffSite ? selectedFlight?.takeoffSite : '-'}</span>
                        </IonCol>
                        <IonCol className='centeredColContent'>
                          <div><b>XC Distance</b></div>
                          <div>{selectedFlight?.xcDistance ? selectedFlight?.xcDistance + ' km' : '-'}</div>
                          <br />
                          <div><b>Height Gain</b></div>
                          <div>{selectedFlight?.heightGain ? selectedFlight?.heightGain + ' ft' : '-'}</div>
                        </IonCol>
                        <IonCol className='centeredColContent'>
                          <IonIcon src={airplaneOutline} style={{ transform: 'rotatez(25deg) rotatex(30deg)', fontSize: '2.5rem' }} color='danger' />
                          <span style={{ marginTop: '0.2rem' }}>{selectedFlight?.landingSite ? selectedFlight?.landingSite : '-'}</span>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCard>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size='12' sizeLg='6'>
                  <IonCard>
                    <IonGrid>
                      <IonRow className='ion-justify-content-center'>
                        <IonCol className='centeredColContent'>
                          <img src={gliderImage} alt='Glider' className='gliderImage' />
                        </IonCol>
                        <IonCol className='centeredColContent'>
                          <div><b>Glider Type</b></div>
                          <div>{selectedFlight?.gliderType ? selectedFlight?.gliderType : '-'}</div>
                        </IonCol>
                        <IonCol className='centeredColContent'>
                          <div><b>Glider Reg</b></div>
                          <div>{selectedFlight?.gliderReg ? selectedFlight?.gliderReg : '-'}</div>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCard>
                  <IonCard>
                    <IonList lines='full' inset className='cardList'>
                      <IonItem>
                        <IonLabel slot='start'>Date</IonLabel>
                        <IonLabel slot='end'>{selectedFlight?.date ? DateTime.fromISO(selectedFlight?.date).toLocaleString(DateTime.DATE_MED) : '-'}</IonLabel>
                      </IonItem>
                      <IonItem>
                        <IonLabel slot='start'>Duration</IonLabel>
                        <IonLabel slot='end'>{selectedFlight?.flightDuration ? Duration.fromMillis(Number(selectedFlight?.flightDuration)).toFormat('h \'hrs\' m \'mins\'') : '-'}</IonLabel>
                      </IonItem>
                      <IonItem>
                        <IonLabel slot='start'>Person in Charge</IonLabel>
                        <IonLabel slot='end'>{selectedFlight?.personInCharge ? selectedFlight?.personInCharge : '-'}</IonLabel>
                      </IonItem>
                      <IonItem>
                        <IonLabel slot='start'>Launch Type</IonLabel>
                        <IonLabel slot='end'>{selectedFlight?.launchType ? selectedFlight?.launchType : '-'}</IonLabel>
                      </IonItem>
                      <IonItem>
                        <IonLabel slot='start'>Cockpit Role</IonLabel>
                        <IonLabel slot='end'>{selectedFlight?.cockpitRole ? selectedFlight?.cockpitRole : '-'}</IonLabel>
                      </IonItem>
                      <IonItem>
                        <IonLabel slot='start'>Weather</IonLabel>
                        <IonLabel slot='end'>{selectedFlight?.weather ? selectedFlight?.weather : '-'}</IonLabel>
                      </IonItem>
                      <IonItem>
                        <IonLabel>
                          <span>Notes</span>
                          <br />
                          <span style={{ fontWeight: 'normal' }}>
                            {selectedFlight?.notes ? selectedFlight?.notes : '-'}
                          </span>
                        </IonLabel>
                      </IonItem>
                    </IonList>
                  </IonCard>
                </IonCol>
                <IonCol size='12' sizeLg='6'>
                  <IonCard>
                    <IonList lines='full' inset className='cardList'>
                      <IonItem>
                        <IonLabel slot='start'>Check Flight</IonLabel>
                        <IonIcon slot='end' src={selectedFlight?.checkFlight ? checkbox : squareOutline} color={selectedFlight?.checkFlight ? 'success' : 'none'} />
                      </IonItem>
                      <IonItem>
                        <IonLabel slot='start'>Stall</IonLabel>
                        <IonIcon slot='end' src={selectedFlight?.stall ? checkbox : squareOutline} color={selectedFlight?.stall ? 'success' : 'none'} />
                      </IonItem>
                      <IonItem>
                        <IonLabel slot='start'>Spin</IonLabel>
                        <IonIcon slot='end' src={selectedFlight?.spin ? checkbox : squareOutline} color={selectedFlight?.spin ? 'success' : 'none'} />
                      </IonItem>
                      <IonItem>
                        <IonLabel slot='start'>Line Break</IonLabel>
                        <IonIcon slot='end' src={selectedFlight?.lineBreak ? checkbox : squareOutline} color={selectedFlight?.lineBreak ? 'success' : 'none'} />
                      </IonItem>
                      <IonItem>
                        <IonLabel slot='start'>Land Out</IonLabel>
                        <IonIcon slot='end' src={selectedFlight?.landOut ? checkbox : squareOutline} color={selectedFlight?.landOut ? 'success' : 'none'} />
                      </IonItem>
                      <IonItem>
                        <IonLabel slot='start'>Soaring Flight</IonLabel>
                        <IonIcon slot='end' src={selectedFlight?.soar ? checkbox : squareOutline} color={selectedFlight?.soar ? 'success' : 'none'} />
                      </IonItem>
                      <IonItem>
                        <IonLabel slot='start'>Friends and Family</IonLabel>
                        <IonIcon slot='end' src={selectedFlight?.fAndF ? checkbox : squareOutline} color={selectedFlight?.fAndF ? 'success' : 'none'} />
                      </IonItem>
                    </IonList>
                  </IonCard>
                  <IonCard>
                    <IonList lines='full' inset className='cardList'>
                      <IonItem>
                        <IonLabel slot='start'>Instructor Name</IonLabel>
                        <IonLabel slot='end'>{selectedFlight?.instructorName ? selectedFlight?.instructorName : '-'}</IonLabel>
                      </IonItem>
                      <IonItem>
                        <IonLabel>
                          <span>Instructor Comments</span>
                          <br />
                          <span style={{ fontWeight: 'normal' }}>
                            {selectedFlight?.instructorComments ? selectedFlight?.instructorComments : '-'}
                          </span>
                        </IonLabel>
                      </IonItem>
                    </IonList>
                  </IonCard>
                </IonCol>
              </IonRow>
            </IonGrid>
            <IonFab vertical="bottom" horizontal="end" slot="fixed">
              <IonFabButton color='tertiary' onClick={() => setShowCreateOrEditModal(true)}>
                <IonIcon src={pencil} size='small' />
              </IonFabButton>
            </IonFab>
          </>
        }
        <CreateOrEditFlight session={session} flightManager={flightManager} setOpen={setShowCreateOrEditModal} open={showCreateOrEditModal} />
      </IonContent>
    </IonPage>
  );
}

export default ViewFlight;
