import React, { useEffect, useRef } from 'react';
import { IonAlert, IonAvatar, IonIcon, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonNote } from '@ionic/react';
import './FlightListItem.css';
import Flight from '../interfaces/Flight';
import { pencilOutline, trash } from 'ionicons/icons';
import { FlightManager } from '../hooks/useFlightManager';
import { DateTime } from 'luxon';

interface FlightListItemProps {
  flightManager: FlightManager;
  flight: Flight;
}

const FlightListItem: React.FC<FlightListItemProps> = ({ flightManager, flight }) => {

  // Create ref for sliding item
  const slidingItemRef = useRef<HTMLIonItemSlidingElement>(null);

  // Close sliding item when a flight is selected
  useEffect(() => {
    slidingItemRef.current?.closeOpened();
  }, [flightManager.selectedFlight]);

  return (
    <>
      <IonItemSliding ref={slidingItemRef}>
        <IonItem routerLink={`/home/flights/${flight.flightId}`} detail={false} lines="full">
          <IonAvatar aria-hidden="true" slot="start" className='textAvatar'>
            <IonLabel>{flight.launchType?.charAt(0)}</IonLabel>
          </IonAvatar>
          <IonLabel className="ion-text-wrap">
            <h2>Flight {flight.flightNumber}</h2>
            <h3>{flight.takeoffSite ? flight.takeoffSite : 'N/A'} ► {flight.landingSite ? flight.landingSite : 'N/A'}</h3>
            <p>{flight.personInCharge ? flight.personInCharge : 'N/A'} • {flight.gliderType ? flight.gliderType : 'N/A'} ({flight.gliderReg ? flight.gliderReg.replace(/-/g, '‑') : 'N/A'})</p>
          </IonLabel>
          <IonNote slot="end">{DateTime.fromISO(flight.date).toLocaleString(DateTime.DATE_MED)}</IonNote>
        </IonItem>
        <IonItemOptions slot="end">
          <IonItemOption color="tertiary" routerLink={`/home/flights/${flight.flightId}/edit`}>
            <IonIcon slot="icon-only" icon={pencilOutline}></IonIcon>
          </IonItemOption>
          <IonItemOption id={`delete-flight-btn-${flight.flightId}`} color="danger">
            <IonIcon slot="icon-only" icon={trash}></IonIcon>
          </IonItemOption>
        </IonItemOptions>
      </IonItemSliding>
      <IonAlert
        trigger={`delete-flight-btn-${flight.flightId}`}
        header='Delete Flight'
        message={`Are you sure you want to delete flight ${flight.flightNumber}?`}
        buttons={[
          { text: 'Cancel', role: 'cancel', handler: () => slidingItemRef.current?.closeOpened() },
          { text: 'Delete', role: 'confirm', handler: () => flightManager.deleteFlight(flight.flightId) }
        ]}
      />
    </>
  );
};

export default FlightListItem;
