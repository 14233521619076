import React, { useEffect, useRef, useState } from 'react';
import { IonAlert, IonButton, IonButtons, IonContent, IonHeader, IonLoading, IonModal, IonTitle, IonToolbar, useIonRouter } from '@ionic/react';
import './CreateOrEditFlight.css';
import { FlightManager } from '../hooks/useFlightManager';
import Flight from '../interfaces/Flight';
import { SessionManager } from '../hooks/useSessionManager';
import InputList from './InputList';
import useInputListManager, { InputListManager } from '../hooks/useInputListManager';

interface CreateOrEditFlightProps {
  session: SessionManager;
  flightManager: FlightManager;
  setOpen: (open: boolean) => void;
  open: boolean;
}

const CreateOrEditFlight: React.FC<CreateOrEditFlightProps> = ({ session, flightManager, setOpen, open }) => {

  // Initialise hooks
  const router = useIonRouter();
  const flightInputList: InputListManager = useInputListManager(flightManager.flightFields, flightManager.selectedFlight);

  // Initialise refs
  const modal = useRef<HTMLIonModalElement>(null);

  // Initialise states
  const [showLoading, setShowLoading] = useState(false);

  // When session userId becomes available, retrieve the flight fields
  useEffect(() => {
    if (session.user?.userId) {
      flightManager.retrieveFlightFields();
    }
  }, [session.user?.userId]);

  // When the modal is dismissed
  const handleDismiss = () => {
    // Close the modal
    setOpen(false);
    // Go back to the flights tab if no flight is selected (as there is no flight to view)
    if (!flightManager.selectedFlight) {
      router.push('/home/flights', 'back');
    }
  };

  // When the confirm button is clicked
  const handleConfirm = async () => {
    // If any required fields are not filled in, return
    if (!flightInputList.requiredFieldsFilled()) {
      flightInputList.setShowErrors(true);
      return;
    }
    // Show loading spinner
    setShowLoading(true);
    // If a flight is selected
    if (flightManager.selectedFlight) {
      // Update selected flight in database with new values
      const response = await flightManager.updateFlight(flightInputList.inputData as Flight);
      // If the response is successful, retrieve the updated flight from the database
      if (response) {
        await flightManager.retrieveFlight(flightManager.selectedFlight.flightId);
      }
    } else {
      // Otherwise, add new flight to database
      await flightManager.addFlight(flightInputList.inputData as Flight);
    }
    // Hide loading spinner
    setShowLoading(false);
    // Close the modal
    setOpen(false);
  };

  return (
    <IonModal ref={modal} isOpen={open} onWillDismiss={handleDismiss}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton id='cancel-button'>
              Cancel
            </IonButton>
          </IonButtons>
          <IonTitle>{flightManager.selectedFlight ? 'Edit' : 'Create'} Flight {flightManager.selectedFlight?.flightNumber}</IonTitle>
          <IonButtons slot="end">
            <IonButton strong={true} onClick={() => handleConfirm()}>
              Confirm
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <InputList inputListManager={flightInputList} />
      </IonContent>
      <IonLoading isOpen={showLoading} message={'Saving flight...'} />
      <IonAlert
        trigger={"cancel-button"}
        header={'Discard Changes'}
        message={'Are you sure you want to discard your changes?'}
        buttons={[
          { text: 'Cancel', role: 'cancel' },
          {
            text: 'Discard', role: 'confirm', handler: () => {
              setTimeout(() => {
                modal.current?.dismiss();
              }, 150);
            }
          }
        ]}
      />
    </IonModal>
  );
}

export default CreateOrEditFlight;

