import React, { useEffect, useRef, useState } from 'react';
import { IonAlert, IonButton, IonButtons, IonContent, IonHeader, IonLoading, IonModal, IonTitle, IonToolbar } from '@ionic/react';
import './CreateOrEditPilot.css';
import { SessionManager } from '../hooks/useSessionManager';
import InputList from './InputList';
import useInputListManager, { InputListManager } from '../hooks/useInputListManager';
import { PilotManager } from '../hooks/usePilotManager';
import Pilot from '../interfaces/Pilot';

interface CreateOrEditPilotProps {
  session: SessionManager;
  pilotManager: PilotManager;
  setOpen: (open: boolean) => void;
  open: boolean;
}

const CreateOrEditPilot: React.FC<CreateOrEditPilotProps> = ({ session, pilotManager, setOpen, open }) => {

  // Initialise hooks
  const pilotInputList: InputListManager = useInputListManager(pilotManager.pilotFields, pilotManager.selectedPilot);

  // Initialise refs
  const modal = useRef<HTMLIonModalElement>(null);

  // Initialise states
  const [showLoading, setShowLoading] = useState(false);

  // When session userId becomes available, retrieve the pilot fields
  useEffect(() => {
    if (session.user?.userId) {
      pilotManager.retrievePilotFields();
    }
  }, [session.user?.userId]);

  // When the modal is dismissed
  const handleDismiss = () => {
    // Close the modal
    setOpen(false);
  };

  // When the confirm button is clicked
  const handleConfirm = async () => {
    // If any required fields are not filled in, return
    if (!pilotInputList.requiredFieldsFilled()) {
      pilotInputList.setShowErrors(true);
      return;
    }
    // Show loading spinner
    setShowLoading(true);
    // If a pilot is selected
    if (pilotManager.selectedPilot) {
      // Update selected pilot in database with new values
      const response = await pilotManager.updatePilot(pilotInputList.inputData as Pilot);
      // If the response is successful, retrieve the updated pilot from the database
      if (response) {
        await pilotManager.retrievePilot(pilotManager.selectedPilot.userId);
      }
    } else {
      // Otherwise, add new pilot to database
      await pilotManager.addPilot(pilotInputList.inputData as Pilot);
    }
    // Hide loading spinner
    setShowLoading(false);
    // Close the modal
    setOpen(false);
  };

  return (
    <IonModal ref={modal} isOpen={open} onDidDismiss={handleDismiss} backdropDismiss={pilotManager.pilotCreated}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton id='cancel-button' disabled={!pilotManager.pilotCreated}>
              Cancel
            </IonButton>
          </IonButtons>
          <IonTitle>{pilotManager.selectedPilot ? 'Edit Pilot Details' : 'Enter Pilot Details'}</IonTitle>
          <IonButtons slot="end">
            <IonButton strong={true} onClick={() => handleConfirm()}>
              Confirm
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <InputList inputListManager={pilotInputList} />
      </IonContent>
      <IonLoading isOpen={showLoading} message={'Saving pilot...'} />
      <IonAlert
        trigger={"cancel-button"}
        header={'Discard Changes'}
        message={'Are you sure you want to discard your changes?'}
        buttons={[
          { text: 'Cancel', role: 'cancel' },
          {
            text: 'Discard', role: 'confirm', handler: () => {
              setTimeout(() => {
                modal.current?.dismiss();
              }, 150);
            }
          }
        ]}
      />
    </IonModal>
  );
}

export default CreateOrEditPilot;

